import * as React from "react";

import {
  Flex,
  Image,
  Text,
  Stack,
  Box,
  SimpleGrid,
  Container,
} from "@chakra-ui/react";

import logo from "../images/logo.svg";

import { FiPhone, FiMail } from "react-icons/fi";

import { Link } from "gatsby";

import styled from "styled-components";

const Footer = () => {
  return (
    <>
      <Box
        bg="black.800"
        color="white.500"
        px={{
          base: "24px",
          md: "48px",
          lg: "96px",
          "2xl": "256px",
        }}
      >
        <Container as={Stack} maxW="100%" py={10}>
          <SimpleGrid
            columns={{
              base: 1,
              sm: 2,
              md: 4,
              /*lg: 4, xl: 5, - v pripade filtrov a autodielov na webe*/
            }}
            spacing={8}
          >
            <Stack align={"flex-start"}>
              <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="red.500">
                Kontakt
              </Text>
              <Text fontWeight="600">DMS-VID, s.r.o.</Text>
              <Flex align="center">
                <FiPhone />
                <Text ml={2}>+421 900 777 888</Text>
              </Flex>
              <Flex align="center">
                <FiMail />
                <Text ml={2}>info@dms-vid.sk</Text>
              </Flex>
              <Text>IČO: 53541707</Text>
              <Text>IČ DPH: SK2121399720</Text>
              <Text>DIČ: 2121399720</Text>
            </Stack>

            <Stack align={"flex-start"}>
              <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="red.500">
                Oleje a kvapaliny
              </Text>
              <Link to="/oleje/?subCategory=motorovy">Motorové oleje</Link>
              <Link to="/oleje/?subCategory=brzdovy">Brzdové kvapaliny</Link>
              <Link to="/oleje/?subCategory=prevodovy">Prevodové oleje</Link>
              <Link to="/oleje/?subCategory=chladiaci">
                Kvapaliny do chladičov
              </Link>
              <Link to="/oleje/?subCategory=ostrekovaci">
                Kvapaliny do ostrekovačov
              </Link>
            </Stack>

            {/*
            <Stack align={"flex-start"}>
              <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="red.500">
                Filtre
              </Text>
              <Link to="/">Olejové filtre</Link>
              <Link to="/">Palivové filtre</Link>
              <Link to="/">Vzduchové filtre</Link>
              <Link to="/">Kabínové filtre</Link>
              <Link to="/">Sady filtrov</Link>
            </Stack>
            */}

            <Stack align={"flex-start"}>
              <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="red.500">
                Autodoplnky
              </Text>
              <Link to="/doplnky/?subCategory=elektronika">Elektronika</Link>
              <Link to="/doplnky/?subCategory=interier">Interiér</Link>
              <Link to="/doplnky/?subCategory=exterier">Exteriér</Link>
              <Link to="/doplnky/?subCategory=cistenie">
                Čistenie a starostlivosť
              </Link>
              <Link to="/doplnky/?subCategory=bezpecnost">Bezpečnosť</Link>
            </Stack>

            {/*
            <Stack align={"flex-start"}>
              <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="red.500">
                Autodiely
              </Text>
              <Link to="/">Pneumatiky</Link>
              <Link to="/">Brzdy</Link>
              <Link to="/">Motor</Link>
              <Link to="/">Odpruženie</Link>
              <Link to="/">Riadenie</Link>
              <Link to="/">Elektronika</Link>
            </Stack>
            */}

            <Stack align={"flex-start"}>
              <Flex>
                <iframe
                  width="100%"
                  height={200}
                  style={{ border: "0", frameborder: "0" }}
                  loading="lazy"
                  allowFullScreen
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBNuxBVM6VUztT0oIJZ_oC3yf3sEXPiqpw&q=Presovska+84,Kapusany+Slovakia&zoom=18"
                  title="Google Maps"
                ></iframe>
              </Flex>
            </Stack>
          </SimpleGrid>
        </Container>
        <Box py={10}>
          <Flex
            align={"center"}
            _before={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "whiteAlpha.300",
              flexGrow: 1,
              mr: 8,
            }}
            _after={{
              content: '""',
              borderBottom: "1px solid",
              borderColor: "whiteAlpha.300",
              flexGrow: 1,
              ml: 8,
            }}
          >
            <Image src={logo} boxSize="20%" />
          </Flex>
          <Text pt={6} fontSize={"sm"} textAlign={"center"}>
            © 2022 DMS-VID, s.r.o. - Všetky práva vyhradené - Designed by{" "}
            <Designer href="https://jan.duras.me/" target="_blank">
              Ján Ďuraš
            </Designer>
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default Footer;

const Designer = styled.a`
  &:hover {
    color: #e8443b;
  }
`;
