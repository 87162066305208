import { extendTheme } from "@chakra-ui/react";

const colors = {
  red: {
    50: "#FDEEED",
    500: "#E8443B",
    900: "#6E120D",
  },
  black: {
    50: "#E0E0E0",
    500: "#8F8F8F",
    800: "#141414",
    850: "#0A0A0A",
    900: "#000000",
  },
  white: {
    500: "#A3A3A3",
    900: "#FFFFFF",
  },
  green: {
    50: "#ADEBC9",
    500: "#2FC171",
    900: "#104226",
  },
};

const theme = extendTheme({
  colors,
});

export default theme;
