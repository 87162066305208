import * as React from "react";
import { useState } from "react";

import {
  Flex,
  Button,
  IconButton,
  Image,
  Text,
  Spacer,
  Box,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";

import { FaFacebook, FaCar } from "react-icons/fa";
import {
  GiElectric,
  GiCarSeat,
  GiSpray,
  GiFirstAidKit,
  GiHomeGarage,
} from "react-icons/gi";
import { FiGrid, FiPhone, FiMail } from "react-icons/fi";
import { RiOilLine } from "react-icons/ri";

import { Link } from "gatsby";

import logo from "../images/logo.svg";

const Navbar = (props) => {
  const [display, changeDisplay] = useState("none");

  return (
    <>
      <Box position="fixed" w="100vw" zIndex={20} boxShadow="dark-lg">
        <Flex
          align="center"
          justify="center"
          py={0.5}
          px={{
            base: "24px",
            md: "48px",
            lg: "96px",
            "2xl": "256px",
          }}
          bg="red.500"
          transition="all 250ms"
          flexWrap="wrap"
        >
          <Flex
            justify="center"
            display={{
              base: "none",
              sm: "flex",
            }}
          >
            <Button
              color="black.900"
              h={2}
              px={0}
              bg="red.500"
              leftIcon={<FaFacebook />}
              size="md"
              variant="link"
            >
              <Center>
                <a
                  href="https://www.facebook.com/DMS-VID-autodoplnky-autodiely-113075940509269"
                  target="_blank"
                >
                  Facebook
                </a>
              </Center>
            </Button>
          </Flex>

          <Spacer display={{ base: "none", sm: "block" }} />

          <Flex flexWrap="wrap" justify="center">
            <Flex
              align="center"
              color="black.900"
              fontWeight="500"
              px={{ base: 3, sm: 5 }}
            >
              <FiPhone />
              <Text ml={2}>+421 900 777 888</Text>
            </Flex>

            <Flex
              align="center"
              color="black.900"
              fontWeight="500"
              display={{
                base: "none",
                sm: "flex",
              }}
            >
              <FiMail />
              <Text ml={2}>info@dms-vid.sk</Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex justify="center">
          <Flex
            align="center"
            w="100%"
            py={2}
            px={{
              base: "24px",
              md: "48px",
              lg: "96px",
              "2xl": "256px",
            }}
            bg="black.800"
            transition="all 250ms"
          >
            {/* Desktop */}
            <Link to="/">
              <Flex>
                <Image src={logo} boxSize="40%" />
              </Flex>
            </Link>

            <Spacer />

            <Flex display={["none", "none", "flex", "flex"]} align="center">
              <Link to="/">
                <Button
                  variant="ghost"
                  aria-label="Domov"
                  pr={{ md: "0.5rem", lg: "1.5rem" }}
                  w="100%"
                  color={props.home}
                  _focus={{
                    outline: "none",
                    color: "red.500",
                  }}
                  _hover={{ color: "white" }}
                  _active={{ background: "none" }}
                  leftIcon={<GiHomeGarage />}
                >
                  Domov
                </Button>
              </Link>

              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  aria-label="Oleje"
                  px={{ md: "0.5rem", lg: "1rem", xl: "1.5rem" }}
                  pl={0}
                  w="100%"
                  color={props.oils}
                  _focus={{
                    outline: "none",
                    color: "red.500",
                  }}
                  _hover={{ color: "white" }}
                  _active={{ background: "none" }}
                  rightIcon={<ChevronDownIcon />}
                  minWidth="none"
                  leftIcon={<RiOilLine />}
                >
                  Oleje a kvapaliny
                </MenuButton>
                <MenuList bg="black.800" borderColor="whiteAlpha.200">
                  <Link to="/oleje">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <FiGrid />
                      </Box>
                      Všetky
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=motorovy">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Motorové oleje
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=brzdovy">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Brzdové kvapaliny
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=prevodovy">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Prevodové oleje
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=chladiaci">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Kvapaliny do chladičov
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=ostrekovaci">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Kvapaliny do ostrekovačov
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>

              {/*
              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  aria-label="Autodoplnky"
                  px={6}
                  w="100%"
                  color="whiteAlpha.600"
                  _hover={{ color: "white" }}
                  _active={{ background: "transparent", color: "white" }}
                  rightIcon={<ChevronDownIcon />}
                  minWidth="none"
                  leftIcon={<RiFilterLine />}
                >
                  Filtre
                </MenuButton>
                <MenuList bg="black.800" borderColor="whiteAlpha.200">
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <FiGrid />
                    </Box>
                    Všetky
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <RiFilterLine />
                    </Box>
                    Olejové filtre
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <RiFilterLine />
                    </Box>
                    Palivové filtre
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <RiFilterLine />
                    </Box>
                    Vzduchové filtre
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <RiFilterLine />
                    </Box>
                    Kabínové filtre
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <RiFilterLine />
                    </Box>
                    Sady filtrov
                  </MenuItem>
                </MenuList>
              </Menu>
            */}

              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  aria-label="Autodoplnky"
                  px={{ md: "0.5rem", lg: "1rem", xl: "1.5rem" }}
                  w="100%"
                  color={props.accessories}
                  _focus={{
                    outline: "none",
                    color: "red.500",
                  }}
                  _hover={{ color: "white" }}
                  _active={{ background: "none" }}
                  rightIcon={<ChevronDownIcon />}
                  minWidth="none"
                  leftIcon={<GiSpray />}
                >
                  Autodoplnky
                </MenuButton>
                <MenuList bg="black.800" borderColor="whiteAlpha.200">
                  <Link to="/doplnky">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <FiGrid />
                      </Box>
                      Všetky
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=elektronika">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiElectric />
                      </Box>
                      Elektronika
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=interier">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiCarSeat />
                      </Box>
                      Interiér
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=exterier">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <FaCar />
                      </Box>
                      Exteriér
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=cistenie">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiSpray />
                      </Box>
                      Čistenie a starostlivosť
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=bezpecnost">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiFirstAidKit />
                      </Box>
                      Bezpečnosť
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>

              {/*
              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  aria-label="Autodiely"
                  px={6}
                  w="100%"
                  color="whiteAlpha.600"
                  _hover={{ color: "white" }}
                  _active={{ background: "transparent", color: "white" }}
                  rightIcon={<ChevronDownIcon />}
                  minWidth="none"
                  leftIcon={<GiCarWheel />}
                >
                  Autodiely
                </MenuButton>
                <MenuList bg="black.800" borderColor="whiteAlpha.200">
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <FiGrid />
                    </Box>
                    Všetky
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <GiCarWheel />
                    </Box>
                    Pneumatiky
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <GiStoneWheel />
                    </Box>
                    Brzdy
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <AiTwotoneHighlight />
                    </Box>
                    Motor
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <GiSpring />
                    </Box>
                    Odpruženie
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <GiSteeringWheel />
                    </Box>
                    Riadenie
                  </MenuItem>
                  <MenuItem
                    color="white.500"
                    _hover={{ background: "blackAlpha.600" }}
                    _focus={{ background: "blackAlpha.600" }}
                    px={5}
                    py={2}
                    fontWeight={500}
                  >
                    <Box color="red.500" mr={3}>
                      <GiCarBattery />
                    </Box>
                    Elektronika
                  </MenuItem>
                </MenuList>
              </Menu>
              */}

              <Link to="/kontakt">
                <Button
                  variant="ghost"
                  aria-label="Kontakt"
                  pl={{ md: "0.5rem", lg: "1rem", xl: "1.5rem" }}
                  pr={0}
                  w="100%"
                  color={props.contact}
                  _focus={{
                    outline: "none",
                    color: "red.500",
                  }}
                  _hover={{ color: "white" }}
                  _active={{ background: "none" }}
                  leftIcon={<FiMail />}
                >
                  Kontakt
                </Button>
              </Link>
            </Flex>

            {/* Mobile */}
            <IconButton
              aria-label="Open Menu"
              size="lg"
              bg="red.500"
              color="black.900"
              icon={<HamburgerIcon />}
              onClick={() => changeDisplay("flex")}
              display={["flex", "flex", "none", "none"]}
            />
          </Flex>

          {/* Mobile Content */}
          <Flex
            w="100vw"
            display={display}
            bgColor="black.800"
            h="100vh"
            pos="fixed"
            top="0"
            left="0"
            overflowY="auto"
            flexDir="column"
          >
            <Flex justify="flex-end">
              <IconButton
                m={6}
                aria-label="Close Menu"
                size="lg"
                bg="red.500"
                color="black.900"
                icon={<CloseIcon />}
                onClick={() => changeDisplay("none")}
              />
            </Flex>

            <Flex flexDir="column" align="center" color="white.500">
              <Link to="/">
                <Button
                  variant="ghost"
                  aria-label="Domov"
                  my={3}
                  _hover={{ background: "transparent", color: "white" }}
                  _active={{ background: "transparent", color: "white" }}
                  w="100%"
                  leftIcon={<GiHomeGarage />}
                  fontSize="lg"
                >
                  Domov
                </Button>
              </Link>

              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  aria-label="Oleje"
                  my={3}
                  _hover={{ background: "transparent", color: "white" }}
                  _active={{ background: "transparent", color: "white" }}
                  rightIcon={<ChevronDownIcon />}
                  leftIcon={<RiOilLine />}
                  fontSize="lg"
                >
                  Oleje a kvapaliny
                </MenuButton>
                <MenuList bg="black.800" borderColor="whiteAlpha.200">
                  <Link to="/oleje">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <FiGrid />
                      </Box>
                      Všetky
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=motorovy">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Motorové oleje
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=brzdovy">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Brzdové kvapaliny
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=prevodovy">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Prevodové oleje
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=chladiaci">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Kvapaliny do chladičov
                    </MenuItem>
                  </Link>

                  <Link to="/oleje/?subCategory=ostrekovaci">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <RiOilLine />
                      </Box>
                      Kvapaliny do ostrekovačov
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  as={Button}
                  variant="ghost"
                  aria-label="Oleje"
                  my={3}
                  _hover={{ background: "transparent", color: "white" }}
                  _active={{ background: "transparent", color: "white" }}
                  rightIcon={<ChevronDownIcon />}
                  leftIcon={<GiSpray />}
                  fontSize="lg"
                >
                  Autodoplnky
                </MenuButton>
                <MenuList bg="black.800" borderColor="whiteAlpha.200">
                  <Link to="/doplnky">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <FiGrid />
                      </Box>
                      Všetky
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=elektronika">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiElectric />
                      </Box>
                      Elektronika
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=interier">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiCarSeat />
                      </Box>
                      Interiér
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=exterier">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <FaCar />
                      </Box>
                      Exteriér
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=cistenie">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiSpray />
                      </Box>
                      Čistenie a starostlivosť
                    </MenuItem>
                  </Link>

                  <Link to="/doplnky/?subCategory=bezpecnost">
                    <MenuItem
                      color="white.500"
                      _hover={{ background: "blackAlpha.600" }}
                      _focus={{ background: "blackAlpha.600" }}
                      px={5}
                      py={2}
                      fontWeight={500}
                    >
                      <Box color="red.500" mr={3}>
                        <GiFirstAidKit />
                      </Box>
                      Bezpečnosť
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>

              <Link to="/kontakt">
                <Button
                  variant="ghost"
                  aria-label="Kotankt"
                  my={3}
                  _hover={{ background: "transparent", color: "white" }}
                  _active={{ background: "transparent", color: "white" }}
                  w="100%"
                  leftIcon={<FiMail />}
                  fontSize="lg"
                >
                  Kontakt
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Navbar;
